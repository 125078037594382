<template>
    <div class="body fixed mobile"> <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header errors">
            <div></div>
            <div class="img"><img :src="titleImage"></div>
            <div></div>
        </section>
        <!-- Main -->
        <div class="main errors">
            <div class="img"><img src="@/assets/icon/error.svg"></div>
            <div class="flexV center">
                <span class="fXXL white">網站錯誤</span>
                <span class="fL secondary"><br>網站發生錯誤</span>
                <span class="fL secondary">請回到首頁重新嘗試</span>
            </div>
            <a class="button fit rounded white" @click.prevent="$router.push('/home')"><span class="fL primary">回首頁</span></a>
        </div>
    </div>
</template>
<script>

export default {
    name: "",
    data() {
        return {
           
        };
    },
    computed: {
        titleImage() {
            return process.env.VUE_APP_TITLE_W_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_TITLE_W_IMAGE_PATH}`) : require("@/assets/img/title.svg");
        }
    },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>